<template>
  <!-- <ValidationProvider v-slot="{ errors }" tag="div" :rules="rules" :mode="validationMode"> -->
  <div class="input-field w-full">
    <!-- INPUT FIELDS -->
    <div class="flex-1 flex justify-between">
      <div class="font-roboto text-text-color text-sm md:text-base">
        Auto generate Registration No
        <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
      </div>
      <div class="flex gap-2 md:gap-3 text-base text-text-color font-roboto">
        <label class="switch">
          <input type="checkbox" :checked="modelValue" @change="change" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <!-- ERRORS -->
    <div class="d-f w-full info-wrapper">
      <small v-if="errors[0]" class="error-msg rtl:mt-1 ltr:-mt-0">
        {{ translatedError(errors[0]) }}
      </small>
    </div>
  </div>
  <!-- </ValidationProvider> -->
</template>

<script>
// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { translatedError } from '@src/utils/generalUtil.js'

export default {
  name: 'UiSwitch',
  components: {
    // ValidationProvider,
  },
  mixins: [],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: { type: [String, Boolean], default: '' },
    rules: { type: String, default: '' },
    validationMode: {
      type: String,
      default: 'aggressive',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      translatedError,
    }
  },
  methods: {
    change(e) {
      this.$emit('update:modelValue', !!e.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-msg {
  font-size: 12px;
  font-style: italic;
  color: var(--text-color-danger);
}

/* STYLE FOR CHECKBOX */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
