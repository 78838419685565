<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="SITE_SETTS"
      tooltip-title="SITE_SETTS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
    <div class="bg-white md:mt-5 pt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter">
      <div
        class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
      >
        <!-- 1st Row -->
        <!-- PERMISSIBLE LANGUAGES -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
          <UiMultiSelect
            :key="`permissible_languages${forceRender}`"
            v-model="permittedLanguage"
            :options="langs"
            :already-selected="alreadySelectedLanguage"
            title="Permitted Languages"
            label="long"
            class="w-full"
            reduce="short"
            :show-lock="true"
            :lock-value="settings.permissible_languages.is_locked_permissible_languages"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'permissible_languages')
              }
            "
          />
        </div>
        <!-- DEFAULT LANGUAGE -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
          <UiSingleSelect
            :key="`default_language${forceRender}`"
            v-model="settings.default_language.default_language"
            label="long"
            :rules="'required'"
            :options="defaultLanguage"
            title="Default Language"
            class="flex-1"
            reduce="short"
            :show-lock="true"
            :lock-value="settings.default_language.is_locked_default_language"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'default_language')
              }
            "
          />
        </div>
        <!-- 2nd Row -->
        <!-- DEFAULT THEME -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
          <UiSingleSelect
            :key="`default_theme${forceRender}`"
            v-model="settings.default_theme.default_theme"
            :rules="'required'"
            :options="defaultTheme"
            title="Default Theme"
            class="flex-1"
            label="label"
            :reduce="'reduce'"
            :show-lock="true"
            :lock-value="settings.default_theme.is_locked_default_theme"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'default_theme')
              }
            "
          />
        </div>
        <!-- PERMISSIBLE THEME -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
          <UiMultiSelect
            :key="`permissible_themes${forceRender}`"
            v-model="permittedThemes"
            :options="themes"
            :already-selected="alreadySelectedThemes"
            title="Permitted Themes"
            label="label"
            :reduce="'reduce'"
            class="w-full"
            :show-lock="true"
            :lock-value="settings.permissible_themes.is_locked_permissible_themes"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'permissible_themes')
              }
            "
          />
        </div>
        <!-- 3rd Row -->
        <!-- DATEFORMAT -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3">
          <UiSingleSelect
            v-model="settings.format_of_date.format_of_date"
            :rules="'required'"
            :options="defaultDateArray"
            title="Date Format"
            class="flex-1"
          />
        </div>
        <!-- DEFAULT Timezone -->
        <div
          v-permission="siteSettingsPermissions"
          class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3"
        >
          <UiSingleSelect
            v-model="settings.default_timezone.default_timezone"
            :rules="'required'"
            :options="defaultTimeZoneArray"
            title="Default Timezone"
            class="flex-1"
          />
        </div>
        <!-- 4th Row -->
        <!-- START DAY OF WEEK -->
        <div
          v-permission="siteSettingsPermissions"
          class="sm:w-1/2 w-full sm:pt-0 pt-4 pr-0 sm:pr-3"
        >
          <UiSingleSelect
            :key="`week_start_day${forceRender}`"
            v-model="settings.week_start_day.week_start_day"
            label="long"
            :rules="'required'"
            :options="weekdaysArray"
            title="Start day of week"
            class="flex-1"
            :show-lock="true"
            :lock-value="settings.week_start_day.is_locked_week_start_day"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'week_start_day')
              }
            "
          />
        </div>
        <!-- CURRENCIES FOR FEE AND FINANCE -->
        <div class="sm:w-1/2 w-full sm:pt-0 pt-4 pl-0 sm:pl-3">
          <UiSingleSelect
            :key="`default_currency${forceRender}`"
            v-model="settings.default_currency.default_currency"
            :rules="'required'"
            :options="currenciesList"
            title="Currencies For Fees and Finance"
            class="flex-1"
            :show-lock="true"
            :lock-value="settings.default_currency.is_locked_default_currency"
            reduce="id"
            @updateLockValue="
              (e) => {
                updateLockValue(e, 'default_currency')
              }
            "
          />
        </div>
        <div
          v-permission="siteSettingsPermissions"
          class="sm:w-1/2 w-full sm:pt-0 pt-0 sm:pr-6 pr-0 relative mt-2"
        >
          <span
            class="float-right absolute right-0 sm:pr-6 z-50 pr-0 cursor-pointer"
            @click="togglePatternModal()"
          >
            <icon
              class="icon opacity-50 lg:opacity-100 cursor-pointer"
              color="white"
              icon="edit"
              height="15"
              width="15"
            />
          </span>
          <InputBox
            v-model="regPattern"
            type="text"
            title="IB_RP"
            placeholder="IB_RP"
            :disabled="true"
            class="flex-1"
          />
        </div>
      </div>
      <!-- I disabled it because it not required for now and its not in working state -->
      <div v-if="false" class="sm:w-1/2 w-full sm:pt-0 pt-0 pr-0 sm:pr-3 mt-2">
        <UiSwitch v-model="settings.auto_generate_registration_no.auto_generate_registration_no" />
      </div>
      <div class="pb-5 md:pb-0">
        <button
          class="mb-5 mt-5 md:mt-10 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
          :disabled="isLoading"
          @click.prevent="handleSubmit(apply(invalid))"
        >
          {{ $t('dashboard.Save') }}
        </button>
      </div>
    </div>
    <!-- </ValidationObserver> -->
    <RegistrationPatternModal
      v-if="registrationProcessModal"
      :modal="registrationProcessModal"
      @toggle="togglePatternModal"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapState, mapActions } from 'vuex'
/* IMPORTS */
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiSwitch from '@components/UiElements/UiSwitch.vue'
import { objectDeepCopy } from '@src/utils/generalUtil'
import icon from '@components/icons/icon.vue'
import RegistrationPatternModal from '@views/settings/system-settings/registration-pattern/RegistrationPatternModal.vue'
export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    UiMultiSelect,
    InputBox,
    UiSwitch,
    // ValidationObserver,
    icon,
    RegistrationPatternModal,
  },
  page: {
    title: 'System Settings | Site Settings',
    meta: [
      {
        name: 'description',
        content: 'Site Settings',
      },
    ],
  },
  data() {
    return {
      siteSettingsPermissions: {
        env: ['local'],
      },
      langs: this.$store.state.base.languages,
      themes: this.$store.state.settings.siteSettingsDefaults.themesObjArray,
      permittedLanguage: [],
      permittedThemes: [],
      alreadySelectedLanguage: [],
      alreadySelectedThemes: [],
      settings: Object.assign(
        {},
        JSON.parse(JSON.stringify(this.$store.state.settings.siteSetting)),
      ),
      forceRender: 0,
      registrationProcessModal: false,
      regPattern: '',
    }
  },
  computed: {
    defaultLanguage() {
      return this.langs.filter((val) => {
        return this.permittedLanguage.includes(val.short)
      })
    },
    defaultTheme() {
      return this.themes.filter((val) => {
        return this.permittedThemes.includes(val.reduce)
      })
    },
    ...mapState({
      defaultDateArray: (state) =>
        objectDeepCopy(state.settings.siteSettingsDefaults.defaultDateArray),
      defaultTimeZoneArray: (state) =>
        objectDeepCopy(state.settings.siteSettingsDefaults.defaultTimeZoneArray),
      weekdaysArray: (state) => objectDeepCopy(state.settings.siteSettingsDefaults.weekdaysArray),
      currenciesList: (state) => objectDeepCopy(state.settings.siteSettingsDefaults.currenciesList),
      fileSizeArray: (state) => objectDeepCopy(state.settings.siteSettingsDefaults.fileSizeArray),
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  mounted() {
    this.langs.forEach((lang) => {
      if (this.settings.permissible_languages.permissible_languages.includes(lang.short)) {
        this.alreadySelectedLanguage.push(lang)
      }
    })
    this.themes.forEach((theme) => {
      if (this.settings.permissible_themes.permissible_themes.includes(theme.reduce)) {
        this.alreadySelectedThemes.push(theme)
      }
    })
  },
  methods: {
    async apply(invalid) {
      if (!invalid) {
        delete this.settings.deactivated_at
        this.settings.permissible_languages.permissible_languages = this.permittedLanguage
        this.settings.permissible_themes.permissible_themes = this.permittedThemes
        await this.postSiteSettings(this.settings)
      }
    },

    updateLockValue(e, key) {
      this.settings[key][`is_locked_${key}`] = e
    },

    togglePatternModal(payload) {
      this.registrationProcessModal = !this.registrationProcessModal
      if (payload) this.regPattern = payload
    },

    ...mapActions('settings', ['postSiteSettings']),
  },
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 17px;
  height: 16px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-green);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-green);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
